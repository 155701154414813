<template>
  <div class="dotsContextMenu" @click.stop="menuDisplay = !menuDisplay">
    <img :id="'icon_'+_uid" src="../../../assets/more.svg" draggable=false class="unselectable" />
    <transition name="bounce">
      <div v-if="menuDisplay" class="contextMenu" :class="{ onTop: displayOnTop }" :id="contextMenu_uid" ref="menuRef"
        v-click-outside="close">

        <div v-if="lines && lines.length > 0">
          <p v-for="(line, index) in lines" :key="`${line}_${index}`"
            :class="{ 'red': red != undefined && red.includes(line) }"
            @click.stop="menuDisplay = false; $emit('clicked', { 'line': line, 'index': index })">{{ line }}</p>
        </div>

      </div>
    </transition>
  </div>
</template>

<script>

//NPM
import $ from "jquery";
import { v4 as uuidv4 } from 'uuid';

//STORE
import store from "../../../store"

export default {
  name: "dotsVdoMenu",
  store: store,
  props: ["lines", "red"],
  emits: ["clicked"],

  data() {
    return {
      menuDisplay: false,
      displayOnTop: false,
      _uid : uuidv4()
    };
  },
  created() {
    this.contextMenu_uid = "context_menu_" + this._uid;
  },
  mounted() {
  },
  watch: {
    menuDisplay() {
      const list = $('#videoList')
      let rowPosition;
      //Find parent row
      list.children().get().forEach(child => {
        if ($(child).find(`#${this._uid}`).length >= 1) {
          rowPosition = $(child).position().top
        }
      })

      const treshold = list.innerHeight() - 120

      if (rowPosition >= treshold && treshold > 50) {
        this.displayOnTop = true
      }
    }
  },
  methods: {
    close() {
      if (this.menuDisplay) {
        this.menuDisplay = false
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style src="./style.css" scoped/>