<template>
    <div>

        <div class="header center">
            <img @click="goToHome" class="goToHome" src="../../assets/arrow.svg" />
            <h1>{{ project_name }}</h1>
            <DotsVdoMenu class="contextualMenu" :lines="[$t('CreativeSpace.generate.button_action')]"
                @clicked="threeDotsClicked($event)" />

        </div>

        <div class="timeline">
            <div v-for="clip in project_timeline" :key="clip" class="clip center"
                :class="{ 'recordable': (clip.clip_type == 1 && clip.imported != true), 'readyStatus': clip.media_id != null }"
                @click="goToClip(clip._id, clip.media_id)">

                <Clip_type_1 v-if="clip.clip_type == 1" :clip="clip" />

                <!-- ICON -->
                <img v-if="clip.clip_type == 0 && clip.anim_type == 0" src="../../assets/animation.svg"
                    style="background-color: #44bce1" class="icon" />
                <img v-if="clip.clip_type == 0 && clip.anim_type == 1" src="../../assets/title.svg"
                    style="background-color: #44e3a9" class="icon" />
                <img v-if="clip.clip_type == 0 && clip.anim_type == 2" src="../../assets/animation.svg"
                    style="background-color: #c298f5" class="icon" />


                <!-- TEXT -->
                <p class="text" v-if="clip.clip_type == 0 && clip.anim_type == 0">
                    {{ $t("ScriptPart.animation.intro") }}
                </p>
                <p class="text" v-if="clip.clip_type == 0 && clip.anim_type == 1">
                    {{ clip.title_content || $t("ScriptPart.animation.title") }}
                </p>
                <p class="text" v-if="clip.clip_type == 0 && clip.anim_type == 2">
                    {{ $t("ScriptPart.animation.outro") }}
                </p>

            </div>
        </div>
    </div>
</template>

<script>

//STORE
import Vuex from "vuex";
import store from "../../store/index";
import axios from "axios"

import { displayDate } from "../../lib/date-time-duration";

import DotsVdoMenu from "../../components/Object/ContextualMenu/ContextualMenu.vue"
import Clip_type_1 from "./components/Clip_type_1.vue"

export default {
    data() {
        return {
            recorder_url: process.env.RECORDER_URL,
            project_id: null,
            uploads: []
        }
    },
    name: "Tableau de bord",
    store: store,
    components: {
        DotsVdoMenu,
        Clip_type_1
    },
    async mounted() {
        //LOAD PROJECT (STORE)
        this.project_id = this.$route.params.project_id;

        await this.loadProject(this.project_id)


        //Launch deamon refresh media id in clip
        this.refreshMedias()

    },
    unmounted() {
        // projects: []
        this.$store.commit('CLEAR_PROJECTS')
    },
    computed: {
        ...Vuex.mapGetters([
            "isAdmin",
            "getCurrentUsrId"
        ]),
        project_name() {
            return this.$store.state.creativeSpace.project_data.project_name
        },
        project_timeline() {
            return this.$store.state.creativeSpace.project_data.project_timeline
        },
        getUuploadProgression(clip_id) {

            let clip = this.uploads.find(upload => upload.clip_id == clip_id)
            if (clip != undefined) {
                return clip.upload_progression || 0
            } else {
                return 0
            }
        }
    },
    methods: {
        displayDate,
        alert(message) {
            alert(message)
        },
        ...Vuex.mapActions([
            "loadProject",
            "complete_onboarding_task",
            "refreshMedias"
        ]),
        goToHome() {
            this.$router.push({
                name: "Home - Mobile"
            })
        },
        goToClip(clip_id, media_id) {
            if (media_id != null) {
                let url = `${this.recorder_url}?project_id=${this.project_id}&clip_id=${clip_id}&language=${this.$i18n.locale}`
                window.location.href = url
            }
        },

        threeDotsClicked(event) {

            switch (event.index) {
                case 0:
                    this.waiting_for_api_response = true

                    axios.post(`/videos/generate`, null, {
                        params: { project_id: this.project_id },
                        timeout: 60000
                    })
                        .then(() => {
                            //COMPLETE ONBOARDING TASK
                            this.complete_onboarding_task('generate_video')

                            this.waiting_for_api_response = false
                            this.Toaster.success(this.$t("CreativeSpace.success.generation"));
                            this.$router.push({
                                path: "/videos",
                            });
                        })
                        .catch((err) => {
                            this.waiting_for_api_response = false

                            this.Toaster.warn(this.$t("CreativeSpace.generate.projectIncomplet"));
                        });
            }
        },
    }
}
</script>

<style lang="scss" src="./CreativeSpaceMobile.scss"></style>