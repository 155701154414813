<template>
    <img src="../../../assets/video_media.png" style="background-color: #f4b46a" class="icon" />
    <p class="text scrpt_text" v-if="clip.clip_type == 1">{{ clip.media?.trancription?.text || clip.scrpt_text ||
        $t('ScriptPart.clip_type_1.title') }}</p>
    <input ref="file_input" style="display: none;" type="file" accept="video/*" @change="handelFile" />
    <!-- CLIP STATUS -->
    <span v-if="(clip.media_id != null && clip.ready == false) || (upload_progression == 100 && clip.ready == false)"
        class="loading_spinner optimizationLoader" style="--color:var(--color2)"></span>
    <img v-else-if="clip.media_id && clip.ready == true" class="readyCheck" src="../../../assets/checked_blue.svg" />

    <div class="actionButtons" v-if="clip.media_id == null && upload_progression == 0">
        <img src="../../../assets/camera_blue.svg" @click.stop="goToClip()">
        <img src="../../../assets/upload.png" @click.stop="$refs.file_input.click()">
    </div>

    <span class="uploadProgression" :style="'--upload_percent : ' + upload_progression + '%'"
        v-if="upload_progression != 100"></span>
</template>


<script>
import Vuex from "vuex";
import axios from "axios"

export default {
    props: ['clip'],
    data() {
        return {
            project_id: this.$parent.project_id,
            upload_progression: 0,
            recorder_url: this.$parent.recorder_url
        }

    },
    computed: {
        ...Vuex.mapGetters([
            "isAdmin",
            "getCurrentUsrId"
        ]),
    },
    methods: {
        ...Vuex.mapActions([
            "loadProject",
            "complete_onboarding_task"
        ]),
        goToClip() {
            let url = `${this.recorder_url}?project_id=${this.project_id}&clip_id=${this.clip._id}&language=${this.$i18n.locale}`
            //window.open(url, '_blank').focus();
            window.location.href = url
        },
        handelFile(evt) {
            let file = evt.target.files[0];

            let formData = new FormData()
            formData.append('file', file)
            formData.append('usr_id', this.getCurrentUsrId)
            formData.append('media_type', "video_record")
            formData.append('imported', true)


            axios.post('/projects/' + this.project_id + '/timeline/' + this.clip._id + '/origin', formData, {
                //REPORT UPLOAD PROGRESS
                onUploadProgress: (progressEvent => {
                    let upload_progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    this.upload_progression = upload_progress
                })
            })
                .then(async (res) => {

                    if (res.data && res.data.media_id) {


                        //this.media_id = res.data.media_id
                        //this.clip_data.media_id = res.data
                        //this.init()
                    }
                })
                .catch((err) => {
                    console.error(err?.response?.data)
                    if (err?.response?.data?.errorCode == "E0401") {
                        this.Toaster.warn(this.$t("CatalogPanel.error.mediaTooHeavy"));
                    } else if (err?.response?.data?.errorCode == "E0402") {
                        this.Toaster.warn(this.$t("CatalogPanel.error.mediaTooLong", { time: this.maximal_time }));
                    }

                })

        }
    }
}
</script>


<style src="./clip_type_1.scss" lang="scss"></style>